/* Flexbox
// ================================================== */

/* Flex direction */
.inline-flex-row {
    display: inline-flex;
    flex-direction: row;
}

.inline-flex-column {
    display: inline-flex;
    flex-direction: column;
}

.inline-flex-row-reverse {
    display: inline-flex;
    flex-direction: row-reverse;
}

.inline-flex-column-reverse {
    display: inline-flex;
    flex-direction: column-reverse;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

/* Flex wrap */
.flex-nowrap {
    flex-wrap: nowrap;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

/* Justify content */
.content-center {
    justify-content: center;
}

.content-end {
    justify-content: flex-end;
}

.content-start {
    justify-content: flex-start;
}

.content-space-around {
    justify-content: space-around;
}

.content-space-between {
    justify-content: space-between;
}

.content-space-evenly {
    justify-content: space-evenly;
}

/* Align self */
.justify-self-auto {
    align-self: auto;
}

.justify-self-center {
    align-self: center;
}

.justify-self-baseline {
    align-self: baseline;
}

.justify-self-start {
    align-self: flex-start;
}

.justify-self-end {
    align-self: flex-end;
}

.justify-self-stretch {
    align-self: stretch;
}

/* Align items */
.justify-items-center {
    align-items: center;
}

.justify-items-baseline {
    align-items: baseline;
}

.justify-items-start {
    align-items: flex-start;
}

.justify-items-end {
    align-items: flex-end;
}

.justify-items-stretch {
    align-items: stretch;
}

/* Flex grow */
.flex-grow-0 {
    flex-grow: 0;
} /* Default */
.flex-grow-1 {
    flex-grow: 1;
}
.flex-grow-2 {
    flex-grow: 2;
}

/* Flex shrink */
.flex-shrink-0 {
    flex-shrink: 0;
}
.flex-shrink-1 {
    flex-shrink: 1;
} /* Default */

/* Flex basis */
.flex-basis-auto {
    flex-basis: auto;
} /* Default */
.flex-basis-0 {
    flex-basis: 0;
}


.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.position-absolute {
    position: absolute;

}

.position-relative {
    position: relative;
}