.column-layout--space-32 {
  display: flex;
  flex-direction: column;
  row-gap: var(--eds-space-32);
}

.column-layout--space-24 {
  display: flex;
  flex-direction: column;
  row-gap: var(--eds-space-24);
}

.column-layout--space-16 {
  display: flex;
  flex-direction: column;
  row-gap: var(--eds-space-16);
}

.title, .vcenter {
  display: flex;
  align-items: center;
}

.inherit-text-color {
  color: inherit;
}

.no-flex-shrink {
  flex-shrink: 0;
}
