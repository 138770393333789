body {
    /*margin: 0;*/
    background-color: var(--eds-color-grey-6);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* restyle default date input */
input[type='date'],
.eds-input-box input[type='date'] {
    display: inline-flex;
    width: 180px;
}


title, .vcenter {
    display: flex;
    align-items: center;
}


/**
CLICKABLE TEXT
 */
.text__clickable {
    cursor: pointer;
    color: var(--eds-color-text-link);
    background-color: transparent;
}

.text__clickable:hover {
    text-decoration: underline;
}

.text__clickable:visited {
    color: var(--eds-color-text-link-visited);
}

.cursor-pointer {
    cursor: pointer;
}


/**
SNACK_OVERLAY
 */

.snack__overlay {
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
}




